import React, { useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { graphql } from "gatsby"
import Modal from 'react-modal'
import { FaTimes } from "react-icons/fa";
import "../styles/home.scss"
import SEO from '../components/seo'
import DynamicColor from '../components/dynamic-color'
import { getBusinessTypes } from '../utils/utils'

import logo from "../images/fait_maison.png"

import Layout from "../components/layout"
export default function Home({ data }) {
  const swipeable = data.restaurant.websiteSettings?.restoImages.length > 1 ? true : false
  const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 50,
    height: 50,
    cursor: 'pointer',
  };

  const [iframe, showIframe] = useState(data.restaurant.websiteSettings.popupMessage !== '' && data.restaurant.websiteSettings.popupMessage.length > 20);
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '50px'
    },
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.7)',
    }
  };

  return (
    <Layout data={data}>
      <SEO />
      <DynamicColor data={data} />
      <div className="home-page">
        <Carousel
          className="presentation-mode"
          showThumbs={false}
          infiniteLoop
          showIndicators={false}
          stopOnHover={false}
          showStatus={false}
          interval={5000}
          transitionTime={400}
          showArrows={swipeable}
          autoPlay={swipeable}
          swipeable={swipeable}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 0 }}>
                <i className="arrow left"></i>
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 0 }}>
                <i className="arrow right"></i>
              </button>
            )
          }
        >
          {data.restaurant.websiteSettings?.restoImages.map(img => {
            return (
              <div key="content-0" className="my-slide content">
                <img src={`${img.path}`} alt="Interieur du restaurant" />
              </div>
            )
          })}
        </Carousel>

        <div className="banner">
          <div className="business-name">
            {data.restaurant.informations.name}
          </div>
          <div className="business-type">
            {getBusinessTypes(data.restaurant.informations.businessType)}
          </div>
          <div className="business-address">
            {data.restaurant.informations.address}<br />
            {data.restaurant.informations.postalCode} {data.restaurant.informations.city}
          </div>
          <div className="business-services">
            {data.restaurant.urlName !== 'hellodarling' && (
              <img src={logo} alt="logo" width="50px" color="white" />
            )}
            {/* <a href={`${data.restaurant.websiteSettings?.linkInstagram}`} target="_blank" rel="noreferrer"><FaInstagram size="2.4em" /> </a><br />
            <a href={`${data.restaurant.websiteSettings?.linkInstagram}`} target="_blank" rel="noreferrer"><FaInstagram size="2.4em" /> </a><br />
            <a href={`${data.restaurant.websiteSettings?.linkInstagram}`} target="_blank" rel="noreferrer"><FaInstagram size="2.4em" /> </a><br />
            <a href={`${data.restaurant.websiteSettings?.linkInstagram}`} target="_blank" rel="noreferrer"><FaInstagram size="2.4em" /> </a><br />
            <a href={`${data.restaurant.websiteSettings?.linkInstagram}`} target="_blank" rel="noreferrer"><FaInstagram size="2.4em" /> </a><br /> */}
          </div>
        </div>

        <Modal
          isOpen={iframe}
          onRequestClose={() => showIframe(!iframe)}
          style={customStyles}
          contentLabel="Restaurant message"
          ariaHideApp={false}
        >
          <div style={{ padding: '10px 20px 20px 20px' }} dangerouslySetInnerHTML={{ __html: data.restaurant.websiteSettings?.popupMessage }} />
          <span className="close-btn" onClick={() => showIframe(!iframe)} onKeyDown={() => showIframe(!iframe)} role="button" tabIndex={0}>
            <FaTimes color="black" size="1.8em" />
          </span>
        </Modal>
      </div>
    </Layout >
  )
}

export const query = graphql`
  query PageQuery ($restoId: String!) {
    restaurant(id: {eq: $restoId}) {
      urlName
      informations {
        businessType
        address
        postalCode
        city
        name
        phone
        restoId
      }
      settings {
        color
        takeaway
        delivery
      }
      websiteSettings {
        reservation
        googleMapsUrl
        deliverooUrl
        uberEatsUrl
        logo {
          path
        }
        restoImages {
          path
        }
        popupMessage
        linkFacebook
        linkInstagram
        linkYelp
        linkTwitter
        linkFoursquare
        linkTripadvisor
        color
        colorSecond
        colorBackground
      }
    }
  }
`